import React from "react";
import Modal from "@material-ui/core/Modal";
import VideoModalBody from "./VideoModalBody";

interface VideoModalProps {
  openModal: boolean;
  handleClose(): void;
  lobbyVideo: string;
}

export default function VideoModal(props: VideoModalProps) {
  const { lobbyVideo, openModal, handleClose } = props;

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "rgba(239, 239, 239, 0.8)",
          },
        }}
      >
        <VideoModalBody handleClose={handleClose} lobbyVideo={lobbyVideo} />
      </Modal>
    </div>
  );
}
