import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("User")
class User {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("name", String)
  FirstName: string = "";

  @JsonProperty("last_name", String)
  LastName: string = "";

  @JsonProperty("image", String)
  Image: string = "";

  @JsonProperty("occupation", String)
  Occupation: string = "";

  @JsonProperty("professional_role", String)
  ProfessionalRole: string = "";

  @JsonProperty("job", String)
  Job: string = "";
}

export default User;
