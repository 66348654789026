import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    title: {
      fontWeight: 700,
    },
    subtitle: {
      color: "#F1312D",
      marginBottom: theme.spacing(1),
    },
  })
);

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h4" className={classes.title}>
        Virtual Conference Programme
      </Typography>
      <Typography variant="h5" className={classes.subtitle}>
        Find out what's happening
      </Typography>
    </div>
  );
}
