import React from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";

import { Box, Button, styled } from "@material-ui/core";

import Video from "./auditorium_s-rgb_08.mp4";

const LeftButtonContainer = styled(Box)({
  display: "flex",
  height: "109vh",
  position: "absolute",
  width: "26%",
  alignItems: "center",
  justifyContent: "center",
});

const RightButtonContainer = styled(Box)({
  display: "flex",
  height: "108vh",
  position: "absolute",
  width: "89%",
  alignItems: "center",
  justifyContent: "flex-end",
});


const LobbyButton = styled(Button)({
  width: "80px",
  height: "31px"
});

const Auditorium = () => {
  const history = useHistory();

  const goToLobby = () => {
    history.push("/");
  };

  const getLobbyButton = () => {
    return (
      <LobbyButton
        variant="contained"
        color="secondary"
        onClick={goToLobby}
      >
        Lobby
      </LobbyButton>
    );
  };

  return (
    <>
      <Box position="absolute" width="100%">
        <ReactPlayer url={Video} width="100%" height="100%" playing loop />
      </Box>
      <LeftButtonContainer>
        {getLobbyButton()}
      </LeftButtonContainer>
      <RightButtonContainer>
        {getLobbyButton()}
      </RightButtonContainer>
    </>
  );
};

export default Auditorium;
