import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Container } from "@material-ui/core";

import Header from "./Header";
import Menu from "./Menu";
import Items from "./Items";
import Axios from "axios";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      boxSizing: "border-box",
    },
    loading: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export default function Programme() {
  const classes = useStyles();
  const [active, setActive] = React.useState(0);
  const [data, setData] = React.useState<any[] | null>([]);

  // will be removed soon
  React.useEffect(() => {
    Axios({
      url:"https://ecm.carl.rightbrain.cloud/api/v1/files/filebydays/",
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`
      }
    })
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setData(null);
      });
  }, []);

  if (!data) {
    return null;
  } else if (!data?.length) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="primary" />
      </div>
    );
  }
  const days = data?.map?.((el: any) =>
    moment(el?.date).format("dddd Do MMMM")
  );
  const sessions = !!data
    ? data?.map?.((el: any) => el?.sessions)?.[active]
    : [];

  return (
    <div className={classes.root}>
      <Container>
        <Header />
        <Menu
          days={days}
          active={active}
          onClick={(val: number) => setActive(val)}
        />
        <Items items={sessions} />
      </Container>
    </div>
  );
}
