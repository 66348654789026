import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      display: "flex",
      flexWrap: "wrap"
    },
    menuItem: {
      padding: theme.spacing(2, 0, 0),
      flex: "0 0 20%",
    },
    itemButton: {
      padding: theme.spacing(2, 0),
      fontSize: "13px",
      textAlign: "center",
      borderRadius: "4px",
      backgroundColor: "#A19F9F",
      color: "#ffffff",
      margin: "5px",
      cursor: "pointer",
      transition: "all .15s linear",
      "&:hover": {
        backgroundColor: "#F1312D",
      },
    },
    active: {
      backgroundColor: "#F1312D",
    },
  })
);


type MenuProps = {
  days: string[];
  active: number;
  onClick: (string: number) => void;
};

export default function Menu({ days, active, onClick }: MenuProps) {
  const classes = useStyles();

  return (
    <div className={classes.menu}>
      {days.map((el, index) => {
        return (
          <div key={index} className={classes.menuItem}>
            <Typography
              variant="h6"
              onClick={() => onClick(index)}
              className={`${classes.itemButton} ${
                active === index && classes.active
              }`}
            >
              {el}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
