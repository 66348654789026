import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Grid from "@material-ui/core/Grid/Grid";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Select from "@material-ui/core/Select/Select";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";

import OT from "@opentok/client";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cont: {
      height: "calc(100vh - 150px)",
    },
    root: {
      minWidth: "100%",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
      textAlign: "center",
      margin: theme.spacing(2),
    },
    formControl: {
      marginBottom: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1, 0, 2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: "#F1312D",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#F1312D",
      },
    },
    pos: {
      marginBottom: 12,
    },
  })
);

interface DeviceListProps {
  devices: any[];
  label: string;
  selectedDevice: string;
  onChange: (arg: string) => void;
}

const DeviceList: React.FC<DeviceListProps> = ({
  devices,
  label,
  selectedDevice,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} fullWidth>
      <Box mt={2}>
        <InputLabel>{label}</InputLabel>
      </Box>
      <Box mt={2}>
        <Select
          fullWidth
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          value={selectedDevice}
        >
          {devices.map((s: any) => {
            return (
              <MenuItem key={s.deviceId} value={s.deviceId}>
                {s.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </FormControl>
  );
};

interface IDeviceSelect {
  onConnect: (
    audioDevice: string,
    videoDevice: string,
    audioMuted: boolean,
    videoMuted: boolean
  ) => void;
}

export default function DeviceSelect({ onConnect }: IDeviceSelect) {
  const [audioSource, setAudioSource] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [audioSources, setAudioSources] = useState([]);
  const [videoSources, setVideoSources] = useState([]);

  const [muteAudio, setMuteAudio] = useState(false);
  const [muteVideo, setMuteVideo] = useState(true);

  const classes = useStyles();

  const getDevices = () => {
    OT.getDevices(function (error, devices: any) {
      const audioInputDevices = devices.filter(function (element: any) {
        return element.kind === "audioInput";
      });
      const videoInputDevices = devices.filter(function (element: any) {
        return element.kind === "videoInput";
      });

      setAudioSources(audioInputDevices);
      setVideoSources(videoInputDevices);

      if (audioInputDevices.length === 1) {
        setAudioSource(audioInputDevices[0].deviceId);
      } else {
        const defaultAudioDevice = audioInputDevices.find((d: any) => {
          return d.deviceId === "default";
        });
        if (defaultAudioDevice) setAudioSource(defaultAudioDevice.deviceId);
      }
      if (videoInputDevices.length === 1) {
        setVideoSource(videoInputDevices[0].deviceId);
      } else {
        const defaultVideoDevice = videoInputDevices.find((d: any) => {
          return d.deviceId === "default";
        });
        if (defaultVideoDevice) setVideoSource(defaultVideoDevice.deviceId);
      }
    });
  };

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      (stream) => {
        getDevices();
      },
      (e) => {
        alert("Error, no permission given");
      }
    );
  }, []);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.cont}
    >
      <Grid item lg={5}>
        <Card className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Joining Session
            </Typography>
            <DeviceList
              selectedDevice={videoSource}
              devices={videoSources}
              label="Select Camera"
              onChange={(v: string) => {
                setVideoSource(v);
              }}
            />
            <div style={{ width: "100%" }}></div>
            <DeviceList
              selectedDevice={audioSource}
              devices={audioSources}
              label="Select Microphone"
              onChange={(v: string) => {
                setAudioSource(v);
              }}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="muteAudio"
                    checked={!muteAudio}
                    onChange={(e) => {
                      setMuteAudio(!muteAudio);
                    }}
                  />
                }
                label="Enable Audio"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="muteVideo"
                    checked={!muteVideo}
                    onChange={(e) => {
                      setMuteVideo(!muteVideo);
                    }}
                  />
                }
                label="Enable Video"
              />
            </FormGroup>

            <Button
              className={classes.submit}
              onClick={() => {
                onConnect(audioSource, videoSource, muteAudio, muteVideo);
              }}
            >
              Join Session
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
