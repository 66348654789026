import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { RoomControls } from "..";
import useOpenTok from "react-use-opentok";
import { RootStore } from "../../stores/RootStore";
import initLayoutContainer from "opentok-layout-js";

import "./VonageRoom.css";
import { Drawer, Paper, Tab, Tabs } from "@material-ui/core";

interface Props {
  store?: RootStore;
  sessionID: string;
  onEndCall: () => void;
  videoDevice: string;
  audioDevice: string;
  videoMuted: boolean;
  audioMuted: boolean;
}
const options = {
  maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
  minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
  fixedRatio: true, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
  bigClass: "OT_big", // The class to add to elements that should be sized bigger
  bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
  bigFixedRatio: true, // fixedRatio for the big ones
  bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
  bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
  bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
  animate: false, // Whether you want to animate the transitions
};

const Room: React.FC<Props> = ({
  store,
  sessionID,
  videoDevice,
  audioDevice,
  audioMuted,
  videoMuted,
  onEndCall,
}) => {
  let layoutContainer: any = null;
  let layout: any = null;
  let resizeTimeout: any = null;
  let connectionCount: number = 0;

  const [opentokProps, opentokMethods] = useOpenTok();

  const {
    isSessionConnected,

    // connected data
    session,
    connectionId,
    connections,
    publisher,
  } = opentokProps;

  const {
    initSessionAndConnect,
    disconnectSession,
    publish,
    unpublish,
  } = opentokMethods;

  const [hasAudio, setHasAudio] = useState(!audioMuted);
  const [hasVideo, setHasVideo] = useState(!videoMuted);
  const [isPresenting, setIsPresenting] = useState(false);
  const [user, setUser] = useState<any>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const sessionId = sessionID;
    const { vonage } = store!.services;
    vonage.fetchSessionToken(sessionId).then((resp) => {
      const { api_key, session_id, token, user } = resp.data.data;
      setUser(user);
      initSessionAndConnect({
        apiKey: api_key,
        sessionId: session_id,
        token: token,
        connectionEventsSuppressed: true,
      });
      window.addEventListener("resize", handleWindowResize);
    });
    return () => {
      layoutContainer = null;
      layout = null;
      resizeTimeout = null;
    };
  }, [initSessionAndConnect]);

  useEffect(() => {
    layoutContainer = document.getElementById("layoutContainer");
    layout = initLayoutContainer(layoutContainer, options).layout;
    layout();
  }, [isDrawerOpen]);

  useEffect(() => {
    if (!isSessionConnected) return;
    layoutContainer = document.getElementById("layoutContainer");
    layout = initLayoutContainer(layoutContainer, options).layout;
    publishCamera();
    layout();

    session.on("connectionCreated", handleConnectionCreated);
    session.on("connectionDestroyed", handleConnectionDestroyed);
    session.on("streamCreated", handleStreamCreated);
    session.on("streamDestroyed", handleStreamDestroyed);
    session.on("streamPropertyChanged", handleStreamPropertyChanged);

    return () => {
      session.off("streamCreated", handleStreamCreated);
      session.off("streamDestroyed", handleStreamDestroyed);
      session.off("streamPropertyChanged", handleStreamPropertyChanged);
      window.removeEventListener("resize", handleWindowResize);
      disconnectSession();
    };
  }, [isSessionConnected]);

  useEffect(() => {
    if (publisher.screen) {
      publisher.screen.on("mediaStopped", () => {
        setIsPresenting(false);
        window.dispatchEvent(new Event("resize"));
      });
    }
  }, [publisher]);

  useEffect(() => {
    console.log(connections);
  }, [connections]);

  const handleError = (error: any) => {
    if (error) {
      console.log(error.message);
    }
  };

  const handleStreamPropertyChanged = (event: any) => {
    console.log(event, publisher);
  };

  const publishCamera = () => {
    publish({
      name: "camera",
      element: "publisherContainer",
      options: {
        videoSource: videoDevice,
        audioSource: audioDevice,
        publishAudio: !audioMuted,
        publishVideo: !videoMuted,
        insertMode: "append",
        width: "100%",

        height: "100%",
        name: user.name + " " + user.last_name,

        style: {
          nameDisplayMode: "off",
          audioLevelDisplayMode: "off",
          buttonDisplayMode: "off",
        },
      },
    }).catch((ex: any) => {
      console.log(ex);
    });
  };

  const handleStreamCreated = (event: any) => {
    const subscriber = session.subscribe(
      event.stream,
      "layoutContainer",
      {
        insertMode: "append",
        width: "100%",
        // subscribeToAudio: true,
        // subscribeToVideo: connectionCount > 2 ? false : true,
        height: "100%",
        style: {
          nameDisplayMode: "off",
          audioLevelDisplayMode: "off",
          buttonDisplayMode: "off",
        },
      },
      handleError
    );

    if (event.stream.videoType === "screen") {
      document.getElementById(subscriber.id)!.classList.add("OT_big");
    }
    setTimeout(layout, 100);
  };

  const handleConnectionDestroyed = (event: any) => {
    connectionCount--;
    console.log("A client disconnected. " + connectionCount + " total.");
  };
  const handleConnectionCreated = (event: any) => {
    connectionCount++;
    if (event.connection.connectionId != session.connection.connectionId) {
      console.log("Another client connected. " + connectionCount + " total.");
    }
  };

  const handleStreamDestroyed = (event: any) => {
    console.log(event.stream, session);
    setTimeout(layout, 100);
  };

  const handleWindowResize = () => {
    layoutContainer = document.getElementById("layoutContainer");
    layout = initLayoutContainer(layoutContainer, options).layout;
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      layout();
    }, 250);
  };

  const handleOpenDrawer = () => {
    layoutContainer = document.getElementById("layoutContainer");
    layout = initLayoutContainer(layoutContainer, options).layout;
    setIsDrawerOpen(!isDrawerOpen);
    if (!isDrawerOpen) {
      layout();
    }
  };

  const handleDblClick = (e: any) => {
    layoutContainer = document.getElementById("layoutContainer");
    layout = initLayoutContainer(layoutContainer, options).layout;
    const layoutDivs = document.querySelectorAll(".ot-layout");
    const el = e.target.closest(".ot-layout");
    if (el.classList.contains("OT_big")) {
      el.classList.remove("OT_big");
    } else {
      for (let i = 0; i < layoutDivs.length; i++) {
        layoutDivs[i].classList.remove("OT_big");
      }
      el.classList.add("OT_big");
    }
    layout();
  };

  const handleMuteAudio = () => {
    if (publisher.camera) {
      publisher.camera.publishAudio(!hasAudio);
      setHasAudio(!hasAudio);
    }
  };

  const handleShareScreen = () => {
    layoutContainer = document.getElementById("layoutContainer");
    layout = initLayoutContainer(layoutContainer, options).layout;
    if (!publisher.screen) {
      publish({
        name: "screen",
        element: "layoutContainer",
        options: {
          resolution: "1280x720",
          maxResolution: { width: 1920, height: 1920 },
          insertMode: "append",
          width: "100%",
          height: "100%",
          videoSource: "screen",
        },
      })
        .then(() => {
          setIsPresenting(true);
          layout();
        })
        .catch((e: any) => {
          setIsPresenting(false);
          layout();
        });
    } else {
      if (publisher.screen) {
        unpublish({ name: "screen" });
        layout();
        setIsPresenting(false);
      }
    }
  };

  const handleMuteVideo = () => {
    if (publisher.camera) {
      publisher.camera.publishVideo(!hasVideo);
      setHasVideo(!hasVideo);
    }
  };

  const handleEndCall = () => {
    disconnectSession();
    onEndCall();
  };

  return (
    <div
      style={{
        backgroundColor: "#000",
        height: "100%",
        padding: 0,
        margin: 0,
      }}
    >
      <div
        id="layoutContainer"
        className="layout-container"
        style={{
          width: isDrawerOpen ? "calc(100% - 350px)" : "100%",
          height: "calc(100% - 68px)",
        }}
        onDoubleClick={handleDblClick}
      >
        <div id="publisherContainer" className="publisher-container"></div>
      </div>

      <RoomControls
        onEndCall={handleEndCall}
        onMuteAudio={handleMuteAudio}
        onMuteVideo={handleMuteVideo}
        onShareScreen={handleShareScreen}
        hasAudio={hasAudio}
        hasVideo={hasVideo}
        isPresenting={isPresenting}
        onOpenDrawer={handleOpenDrawer}
        style={{ width: isDrawerOpen ? "calc(100% - 351px)" : "100%" }}
      />

      <Drawer
        anchor="right"
        variant="persistent"
        open={isDrawerOpen}
        style={{ width: "350px" }}
      >
        <div style={{ width: "350px" }}>
          <Paper style={{ width: "350px" }}>
            <Tabs
              value={0}
              onChange={(e: any) => {
                console.log(e);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="People" />
              <Tab label="Chat" />
              <Tab label="Documents" />
            </Tabs>
          </Paper>
        </div>
      </Drawer>
    </div>
  );
};

export default inject("store")(observer(Room));
