
import React from "react";

import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { NavLink, useHistory } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Avatar } from "@material-ui/core";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import BriefcaseImage from "../../assets/images/menu_briefcase.svg";
import MessagesImage from "../../assets/images/menu_messages.svg";
import NotificationsImage from "../../assets/images/menu_notifications.svg";
import AgendaImage from "../../assets/images/menu_my_agenda.svg";

import Logo from "../../assets/images/thermofisher_logo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      paddingLeft: 0,
      backgroundColor: "#000000",
    },
    logo: {
      height: "64px",
    },
    menuButton: {
      marginLeft: theme.spacing(2),
      color: "red",
    },
    smallMenu: {
      padding: "10px 0",
      backgroundColor: "#000000",
      color: "#ffffff",
      marginTop: "-8px",
      marginBottom: "-8px",
      width: "150px",
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    inputRoot: {
      color: "inherit",
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    dividerVertical: {
      backgroundColor: "#ffffff",
      width: "1px",
      height: "30px",
      marginTop: "15px",
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    dividerHorizontal: {
      backgroundColor: "#ffffff",
      height: "1px",
      width: "100%",
      marginTop: "5px",
      marginBottom: "5px",
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    icon: {
      color: "#ffffff",
      filter: "invert(1)",
      width: "20px",
    },
    link: {
      display: "block",
      color: "#ffffff",
      textDecoration: "none"
    },
    socialMediaIcons: {
      position: "fixed",
      paddingBottom: "1.5rem",
      bottom: "0",
      display: "flex",
      width: "250px",
      transition: "all .15s linear",
      backgroundColor: "red",
      "& > svg": {
        width: "50px",
      },
    },
  })
);

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "12px",
    padding: theme.spacing(1.2, 3),
    fontWeight: 400,
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

type Anchor = "top" | "left" | "bottom" | "right";

const drawerStyles = {
  top: "67px",
  backgroundColor: "red",
  color: "#ffffff",
  padding: "3rem 1rem",
  height: "calc(100vh)",
  paddingBottom: "100px",
};

interface IUsers {
  store?: RootStore;
}


const PrimarySearchAppBar: React.FC<IUsers> = ({ store }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  const { users } = store!.stores;
  const history = useHistory();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logoutHandler = async () => {
    await users.logout();
    history.push("/login");
  }

  const toggleDrawer = () => setOpen((prevState) => !prevState);

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
      {[
          {
            label: "Lobby",
            path: "/",
          },
          {
            label: "Auditorium",
            path: "/auditorium",
          },
          {
            label: "Breakout Rooms",
            path: "/",
          },
          {
            label: "Expo Hall",
            path: "/",
          },
        ].map((el, index) => (
            <NavLink to={el.path} className={classes.link} key={index}>
              <ListItem button key={index}>
                  <ListItemText primary={el.label}></ListItemText>
              </ListItem>
            </NavLink>
        ))}
      </List>
      <Divider />
      <List>
        {[
          {
            label: "Conference Programme",
            path: "/",
          },
          {
            label: "Attendees",
            path: "/attendees",
          },
          {
            label: "Resource Centere",
            path: "/",
          },
          {
            label: "Help Deske",
            path: "/",
          },
        ].map((el, index) => (
            <NavLink to={el.path} className={classes.link} key={index}>
              <ListItem button key={index}>
                  <ListItemText primary={el.label}></ListItemText>
              </ListItem>
            </NavLink>
        ))}
      </List>
      <List className={classes.socialMediaIcons}>
        <ListItem>
          <InstagramIcon />
        </ListItem>
        <ListItem>
          <FacebookIcon />
        </ListItem>
        <ListItem>
          <TwitterIcon />
        </ListItem>
        <ListItem>
          <LinkedInIcon />
        </ListItem>
      </List>
    </div>
  );

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <div className={classes.smallMenu}>
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <div className={classes.dividerHorizontal}></div>
        <MenuItem onClick={logoutHandler}>Sign out</MenuItem>
      </div>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className={classes.grow}>
        <AppBar position="fixed">
          <Toolbar className={classes.appBar}>
            <NavLink to="/">
              <img src={Logo} alt="Logo" className={classes.logo} />
            </NavLink>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <BootstrapTooltip title="My Agenda">
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge color="secondary">
                    <img
                      src={AgendaImage}
                      alt="agenda"
                      className={classes.icon}
                    />
                  </Badge>
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip title="My Briefcase">
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge color="secondary">
                    <img
                      src={BriefcaseImage}
                      alt="birefcase"
                      className={classes.icon}
                    />
                  </Badge>
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip title="My Messages">
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge color="secondary">
                    <img
                      src={MessagesImage}
                      alt="messages"
                      className={classes.icon}
                    />
                  </Badge>
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip title="My Notifications">
                <IconButton
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge color="secondary">
                    <img
                      src={NotificationsImage}
                      alt="notifications"
                      className={classes.icon}
                    />
                  </Badge>
                </IconButton>
              </BootstrapTooltip>
              <div className={classes.dividerVertical} />
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar
                  alt="Remy Sharp"
                  src="https://cdn.psychologytoday.com/sites/default/files/styles/image-article_inline_full/public/field_blog_entry_images/2018-09/shutterstock_648907024.jpg?itok=ji6Xj8tv"
                  className={classes.small}
                />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>
      <React.Fragment key="right">
        <SwipeableDrawer
          PaperProps={{
            style: drawerStyles,
          }}
          anchor={"right"}
          open={open}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </React.Fragment>
  );
}

export default inject("store")(observer(PrimarySearchAppBar));