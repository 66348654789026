import React, { useRef, useState } from "react";

import { RootStore } from "../../stores/RootStore";
import injectStore from "../utils";

import { Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { SelectInput, TextFieldInput } from "../../components";
import { filterBy } from "../../static/data/filters";
import { UserInput } from "../../types/filters";

type UserFiltersProps = {
  store?: RootStore;
};

const UserFilters = injectStore((props: UserFiltersProps) => {
  const { stores } = props.store!;

  const [userInput, setUserInput] = useState<UserInput>({
    searchQuery: "",
    filterBy: "",
  });

  const timerRef = useRef<number | undefined>(undefined);
  const DELAY = 500;

  const handleUserInputSearch = (newSearchQuery: string) => {
    setUserInput({
      ...userInput,
      searchQuery: newSearchQuery,
    });
  };

  const handleUserInputSelect = (newInput: string) => {
    setUserInput({
      ...userInput,
      filterBy: newInput,
    });
  };

  const applyUserInputChange = () => {
    timerRef && window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      stores.users.filterUsersBySearchInput(userInput.searchQuery);
      stores.users.filterUsersBySelectInput(userInput.filterBy);
    }, DELAY);
  };
  applyUserInputChange();

  return (
    <Box display="flex" flexDirection="column" width="270px">
      <TextFieldInput
        value={userInput.searchQuery}
        onChange={handleUserInputSearch}
        inputAdornment={<SearchIcon fontSize="small" />}
        placeholder="Search"
      />

      <Box mt={2}>
        <SelectInput
          label="Filter By"
          value={userInput.filterBy}
          onChange={handleUserInputSelect}
          menuItems={filterBy.items}
        />
      </Box>
    </Box>
  );
});

export default UserFilters;
