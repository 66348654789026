import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Programme from "../Programme/Programme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "1200px",
    },
    closeBtn: {
      position: "absolute",
      top: "1rem",
      right: "1rem",
      color: "#F1312D",
    },
  })
);

interface VideoModalBodyProps {
  handleClose(): void;
}

const VideoModalBody = (props: VideoModalBodyProps) => {
  const classes = useStyles();
  const { handleClose } = props;

  return (
    <div>
      <IconButton
        color="secondary"
        aria-label="upload picture"
        component="span"
        onClick={() => handleClose()}
        className={classes.closeBtn}
      >
        <CloseIcon />
      </IconButton>
      <div className={classes.paper}>
        <Programme />
      </div>
    </div>
  );
};

export default VideoModalBody;
