import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Session")
class Session {
  @JsonProperty("id", Number)
  id: number = 0;

  @JsonProperty("title", String)
  title: string = "";

  @JsonProperty("start", String)
  start: string = "";

  @JsonProperty("recorded_file_id", Number)
  recorded_file_id: number = 0;

  @JsonProperty("placeholder_file_id", Number)
  placeholder_file_id: number = 0;
}

export default Session;
