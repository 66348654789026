import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Login")
class Login {
  @JsonProperty("user_name", String)
  user_name: string = "";

  @JsonProperty("password", String)
  password: string = "";
}

export default Login;
