import { BaseService } from ".";
import { IResponse } from "./BaseService";

export default class VonageService extends BaseService {
  public async fetchSessionToken(sessionID: string): Promise<IResponse> {
    try {
      return await this.http.get(
        `/api/v1/vonage/get-session-token?sid=${sessionID}`
      );
    } catch (error) {
      throw error;
    }
  }

  public async createSession(): Promise<IResponse> {
    try {
      return await this.http.get("/api/v1/vonage/create-session");
    } catch (error) {
      throw error;
    }
  }
}
