import { Container } from "@material-ui/core";
import { inject } from "mobx-react";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { RootWrapper } from "../../components";
import DeviceSelect from "../../components/Vonage/DeviceSelect";
import VonageRoom from "../../components/Vonage/Room";
import { RootStore } from "../../stores/RootStore";

interface Props {
  store?: RootStore;
}

interface Params {
  sessionID: string;
}

const Session: React.FC<Props> = ({}) => {
  const [inSession, setInSession] = useState(false);
  const { sessionID } = useParams<Params>();
  const [audioDevice, setAudioDevice] = useState(
    localStorage.getItem("defaultAudioDevice") || ""
  );
  const [videoDevice, setVideoDevice] = useState(
    localStorage.getItem("defaultVideoDevice") || ""
  );
  const [videoMuted, setVideoMuted] = useState(false);
  const [audioMuted, setAudioMuted] = useState(true);

  const handleDisconnect = () => {
    setInSession(false);
  };

  const handleConnect = (
    audioDevice: string,
    videoDevice: string,
    audioMuted: boolean,
    videoMuted: boolean
  ) => {
    setAudioDevice(audioDevice);
    setVideoDevice(videoDevice);
    localStorage.setItem("defaultAudioDevice", audioDevice);
    localStorage.setItem("defaultVideoDevice", videoDevice);
    setVideoMuted(videoMuted);
    setAudioMuted(audioMuted);
    setInSession(true);
  };
  return (
    <RootWrapper>
      {!inSession ? (
        <DeviceSelect onConnect={handleConnect} />
      ) : (
        <VonageRoom
          onEndCall={handleDisconnect}
          sessionID={sessionID}
          videoDevice={videoDevice}
          audioDevice={audioDevice}
          videoMuted={videoMuted}
          audioMuted={audioMuted}
        />
      )}
    </RootWrapper>
  );
};

export default inject("store")(Session);
