import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";

import theme from "./theme";

import { configure } from "mobx";
import { Provider } from "mobx-react";
import { rootStore } from "./stores/RootStore";

import Routes from "./components/Routes/Routes";
import "./App.css";

configure({ enforceActions: "observed" });

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={rootStore}>
        <div style={{ height: 70 }}></div>
        <Router>
          <Routes />
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
