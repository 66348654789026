import React from "react";

import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline"; 
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import EmailIcon from "../../assets/images/email.png";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(8),
    display: "flex",
    flexDirection: "column"
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
  },
  title: {
    fontWeight: 700,
  },
  formControl: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: "relative",
    "& > img": {
      position: "absolute",
      left: "15px",
      top: "55px",
      width: "15px",
      zIndex: 100,
    },
  },
  input: {
    "& > .MuiInputBase-root input": {
      paddingLeft: "45px",
    },
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "#F1312D",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F1312D",
    },
  },
}));

interface Inputs {
  email: string;
}

interface IFormValues {
  firstName: string[];
}

interface IUsers {
  store?: RootStore;
}

const ResetLink: React.FC<IUsers> = ({ store }) => {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<Inputs>();

  const onSubmit = (data: IFormValues) => {
    console.log(data);
  };

  return (
      <Container maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Reset Password!
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.formControl}>
              <label htmlFor="user_name" className={classes.label}>
                Your Email
              </label>
              <img src={EmailIcon} alt="Username Icon" />
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                rules={{ required: true }}
                variant="outlined"
                margin="normal"
                fullWidth
                type="email"
                id="email"
                label=""
                name="email"
                autoComplete="email"
                defaultValue=""
                autoFocus
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              className={classes.submit}
            >
              Reset Password
            </Button>
          </form>
        </div>
      </Container>
  );
};

export default inject("store")(observer(ResetLink));