import React from "react";
import Modal from "@material-ui/core/Modal";
import ProgrammeModalBody from "./ProgrammeModalBody";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& > .MuiContainer-root": {
      width: "1800px !important",
      border: "5px solid red",
    },
  },
}));

interface VideoModalProps {
  openModal: boolean;
  handleClose(): void;
}

export default function ProgrammeModal(props: VideoModalProps) {
  const classes = useStyles();
  const { openModal, handleClose } = props;

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(239, 239, 239, 0.8)",
          },
        }}
      >
        <ProgrammeModalBody handleClose={handleClose} />
      </Modal>
    </div>
  );
}
