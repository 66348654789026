import React, { useState } from "react";

import { RootStore } from "../../stores/RootStore";
import injectStore from "../utils";

import { Avatar, Box, Typography } from "@material-ui/core";
import styled from "@material-ui/styles/styled";

import SupervisorAccountRoundedIcon from "@material-ui/icons/SupervisorAccountRounded";

const SupervisorAccountIcon = styled(SupervisorAccountRoundedIcon)({
  color: "#15c96f",
});

const UserFullName = styled(({ isClicked, ...props }) => (
  <Box {...props}></Box>
))({
  cursor: "pointer",
  color: (props) => props.isClicked && "#f1302d",
});

type UsersListProps = {
  store?: RootStore;
};

const UsersList = injectStore((props: UsersListProps) => {
  const { stores } = props.store!;

  const [selectedUser, setSelectedUser] = useState(0);

  const showUserFullProfile = (userId: number) => {
    setSelectedUser(userId);
    stores.ui.openUserProfile();
    stores.users.getUserById(userId);
  };

  return (
    <Box>
      {stores.users.filteredUsers.map((user) => (
        <Box
          key={user.ID}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="400px"
          p={2}
        >
          <Box display="flex">
            <Avatar alt={user.FirstName} src={user.Image} />
            <Box display="flex" flexDirection="column" ml={3}>
              <UserFullName
                isClicked={selectedUser === user.ID}
                onClick={() => showUserFullProfile(user.ID)}
              >
                <Typography variant="body1">
                  {user.FirstName} {user.LastName}
                </Typography>
              </UserFullName>
              <Box>
                <Typography variant="body2">
                  {user.ProfessionalRole}, {user.Occupation}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <SupervisorAccountIcon />
          </Box>
        </Box>
      ))}
    </Box>
  );
});

export default UsersList;
