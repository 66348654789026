import React from "react";
import ReactPlayer from "react-player";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    closeBtn: {
      position: "absolute",
      top: "1rem",
      right: "1rem",
      color: "#F1312D",
    },
  })
);

interface VideoModalBodyProps {
  handleClose(): void;
  lobbyVideo: string;
}

const VideoModalBody = (props: VideoModalBodyProps) => {
  const classes = useStyles();
  const { lobbyVideo, handleClose } = props;

  return (
    <div>
      <IconButton
        color="secondary"
        aria-label="upload picture"
        component="span"
        onClick={() => handleClose()}
        className={classes.closeBtn}
      >
        <CloseIcon />
      </IconButton>
      <div className={classes.paper}>
        <ReactPlayer url={lobbyVideo} controls />
      </div>
    </div>
  );
};

export default VideoModalBody;
