import React from "react";

import { RootStore } from "../../stores/RootStore";
import injectStore from "../utils";

import { Avatar, Box, Button, Typography, styled } from "@material-ui/core";

const UserImage = styled(Avatar)({
  width: "140px",
  height: "140px",
});

const Connect = styled(Button)({
  backgroundColor: "#F1312D",
});

const GreyBtn = styled(Button)({
  backgroundColor: "#b2b1b2",
});

type UserProfilesProps = {
  store?: RootStore;
};

const UserProfile = injectStore((props: UserProfilesProps) => {
  const { stores } = props.store!;

  return (
    <Box width="400px">
      {stores.ui.isUserProfileDisplayed && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt="50px"
        >
          <UserImage
            src={stores.users.user.Image}
            alt={stores.users.user.FirstName}
          />
          <Box mt={2}>
            <Typography variant="h4">
              {stores.users.user.FirstName} {stores.users.user.LastName}
            </Typography>
          </Box>
          <Typography variant="h5">
            {stores.users.user.ProfessionalRole}
          </Typography>
          <Typography variant="h6">{stores.users.user.Job}</Typography>
          <Box mt={1}>
            <Typography variant="h5">{stores.users.user.Occupation}</Typography>
          </Box>
          <Box display="flex" width="70%" pt={4}>
            <Connect variant="contained">Connect</Connect>
            <GreyBtn variant="contained">Message</GreyBtn>
          </Box>
          <Box display="flex" width="70%">
            <GreyBtn variant="contained">Book a Networking Session</GreyBtn>
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default UserProfile;
