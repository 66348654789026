import { RootStore } from "./RootStore";
import { User } from "./models";
import { observable, runInAction } from "mobx";
import { UserService } from "../services";
import { UserFilterBy } from "../enums/filters";

class UserStore {
  private root: RootStore;
  private api: UserService;

  @observable public users: User[] = [];
  @observable public user: User = new User();
  @observable public filteredUsers: User[] = [];
  @observable public isLoggedin: Boolean = !!window.localStorage.getItem(
    "token"
  );

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.users;
    const token = window.localStorage.getItem("token");
    if (token && token?.trim() !== "") {
      this.api.setToken(token);
    }
  }

  public async getAllUsers() {
    try {
      const users = await this.api.fetchAll();
      runInAction(() => {
        this.users = users;
      });
    } catch (err) {
      console.error(err);
    }
  }

  public async getUserById(id: number) {
    try {
      const user = await this.api.byId(id);
      runInAction(() => {
        this.user = user;
      });
    } catch (err) {
      // handle using ui
      console.error(err);
    }
  }

  public async loginUser(data: any) {
    try {
      const user = await this.api.login(data);
      this.api.setToken(user.data);
      localStorage.setItem("token", user.data);
      await runInAction(() => {
        this.isLoggedin = true;
      });
    } catch (err) {
      throw err;
    }
  }

  public async logout() {
    try {
      localStorage.removeItem("token");
      runInAction(() => {
        this.isLoggedin = false;
      });
    } catch (err) {
      console.log(err);
    }
  }

  public filterUsersBySearchInput(userSearchQuery: string) {
    runInAction(() => {
      if (userSearchQuery !== "") {
        this.filteredUsers = this.users.filter(
          (user) =>
            user.FirstName.toLowerCase().includes(userSearchQuery) ||
            user.LastName.toLowerCase().includes(userSearchQuery) ||
            user.Occupation.toLowerCase().includes(userSearchQuery) ||
            user.ProfessionalRole.toLowerCase().includes(userSearchQuery)
        );
      } else this.filteredUsers = this.users;
    });
  }

  public filterUsersBySelectInput(userInput: string) {
    runInAction(() => {
      if (userInput === UserFilterBy.ROLE) {
        this.filteredUsers = this.filteredUsers
          .slice()
          .sort((a, b) =>
            a.ProfessionalRole.toLowerCase() > b.ProfessionalRole.toLowerCase()
              ? 1
              : -1
          );
      } else if (userInput === UserFilterBy.ORGANIZATION) {
        this.filteredUsers = this.filteredUsers
          .slice()
          .sort((a, b) =>
            a.Occupation.toLowerCase() > b.Occupation.toLowerCase() ? 1 : -1
          );
      }
    });
  }
}

export default UserStore;
