import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  lobbyBannerStyle: {
    // WebkitTransform: 'skewY(-0deg) skewX(15deg)',
    // msTransform: 'skewY(-45deg) skewX(0deg)',
    // WebkitTransform: 'matrix(-3, 1, -,1 ,-1, 100, 100)',
    // WebkitTransform: "perspective(100px) rotateY(-10deg)",
    position: "absolute",
    top: "30vh",
    right: "5.5vw",
    width: "11vw",
    height: "34vh",
    transform: "rotate(-3.5deg)",
    cursor: "pointer",
    clipPath: "polygon(0% 25%, 100% 0%,100% 100%,0% 100%)"
  },
}));

interface LobbyBannerProps {
  lobbyBanner: string;
  handleOpen(): void;
}

const LobbyBanner = (props: LobbyBannerProps) => {
  const classes = useStyles();
  const { lobbyBanner, handleOpen } = props;

  return (
    <div>
      {lobbyBanner && (
        <img
          src={lobbyBanner}
          className={classes.lobbyBannerStyle}
          alt={"#"}
          onClick={() => handleOpen()}
        />
      )}
    </div>
  );
};

export default LobbyBanner;
