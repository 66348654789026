import React from "react";

import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { NavLink, useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import UsernameIcon from "../../assets/images/login_name.png";
import PasswordIcon from "../../assets/images/password.png";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#000000",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  forgotPassword: {
    textAlign: "right",
    marginBottom: "1rem",
    "& > a": {
      color: "#000000",
      textDecoration: "none",
      fontWeight: 600,
    },
  },
  label: {
    fontWeight: 600,
  },
  title: {
    fontWeight: 700,
  },
  subtitle: {
    color: "#F1312D",
    marginBottom: theme.spacing(1),
  },
  formControl: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: "relative",
    "& > img": {
      position: "absolute",
      left: "15px",
      top: "55px",
      width: "15px",
      zIndex: 100,
    },
  },
  input: {
    "& > .MuiInputBase-root input": {
      paddingLeft: "45px",
    },
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "#F1312D",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F1312D",
    },
  },
  error: {
    color: "#F1312D",
    display: "block",
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));

interface Inputs {
  user_name: string;
  password: string;
}

interface IFormValues {
  firstName: string[];
}

interface IUsers {
  store?: RootStore;
}

const Login: React.FC<IUsers> = ({ store }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const { handleSubmit, control, errors } = useForm<Inputs>();

  const { users } = store!.stores;
  const history = useHistory();

  const onSubmit = async (data: IFormValues) => {
    if (loading) return;
    setLoading(true);
    setError(false);
    try {
      await users.loginUser(data);
      history.replace(
        "/session/1_MX40Njk0NjY5NH5-MTYwMzA5NjUyNDA1OH5BczhoQk1KdTlSakNtWEFUUDhtYXV5YmJ-fg"
      );
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {error && (
        <Alert severity="error">Username or Password is incorrect.</Alert>
      )}
      <CssBaseline />
      <div className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          Welcome!
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          Access the Virtual Conference
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formControl}>
            <label htmlFor="user_name" className={classes.label}>
              Username
            </label>
            <img src={UsernameIcon} alt="Username Icon" />
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              rules={{ required: true }}
              variant="outlined"
              margin="normal"
              fullWidth
              id="user_name"
              label=""
              name="user_name"
              autoComplete="user_name"
              defaultValue=""
              autoFocus
            />
          </div>
          {errors?.user_name && (
            <span className={`${classes.error} ${classes.mb2}`}>
              Username is required
            </span>
          )}
          <div className={classes.formControl}>
            <label htmlFor="password" className={classes.label}>
              Password
            </label>
            <img src={PasswordIcon} alt="Password Icon" />
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              rules={{ required: true }}
              variant="outlined"
              margin="normal"
              fullWidth
              type="password"
              id="password"
              label=""
              name="password"
              autoComplete="password"
              defaultValue=""
            />
          </div>
          {errors?.password && (
            <span className={classes.error}>Password is required</span>
          )}
          <div className={classes.forgotPassword}>
            <NavLink to="/forgot-password">Forgot your password?</NavLink>
          </div>
          <Button type="submit" variant="contained" className={classes.submit}>
            {loading ? "Signing in..." : "Sign In"}
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default inject("store")(observer(Login));
