import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";

import Item from "./Item";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    items: {
      padding: theme.spacing(3, 0),
    },
  })
);


type ItemsProps = {
  items: any[];
};


export default function Items({ items }: ItemsProps) {
  const classes = useStyles();

  return (
    <div className={classes.items}>
      {
        items?.map?.((item: any, index: number) => {
          const start = moment(item?.start).format("LT") || '-';
          const end = moment(item?.end).format("LT")  || '-';
          const location = !!item?.breakout_session ? 'Breakout Room' : 'Auditorium';
          const text = item?.title || '-';
          const isRegistered = false;
          return <Item key={index} start={start} end={end} location={location} text={text} isRegistered={isRegistered}/>
        })
      }
    </div>
  );
}
