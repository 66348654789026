import { RootStore } from "./RootStore";
import { observable, runInAction } from "mobx";

class UIStore {
  private root: RootStore;

  @observable public isUserProfileDisplayed: Boolean = false;

  constructor(root: RootStore) {
    this.root = root;
  }

  public openUserProfile() {
    runInAction(() => {
      this.isUserProfileDisplayed = true;
    });
  }
}

export default UIStore;
