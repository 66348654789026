import React, { useEffect } from "react";

import { RootStore } from "../../stores/RootStore";
import injectStore from "../utils";

import { Box, Typography, styled } from "@material-ui/core";
import theme from "../../theme";

import { RootWrapper } from "../../components";

import UsersList from "./UsersList";
import UserProfile from "./UserProfile";
import UserFilters from "./UserFilters";
import AttendeesIcon from "../../static/icons/attendees.svg";

const AttendeesBoard = styled(Box)({
  backgroundColor: theme.palette.background.paper,
  padding: "20px",
});

const Header = styled(Box)({
  backgroundColor: "#F1F2F5",
  padding: "16px 0 16px 24px",
});

const Divider = styled(Box)({
  backgroundColor: "#F1F2F5",
  width: "10px",
});

type AttendeesProps = {
  store?: RootStore;
};

const Attendees = injectStore((props: AttendeesProps) => {
  const { stores } = props.store!;

  useEffect(() => {
    stores.users.getAllUsers();
  }, [stores.users.filteredUsers]);

  return (
    <RootWrapper>
      <AttendeesBoard>
        <Header display="flex" alignItems="center">
          <img src={AttendeesIcon} width="25px" />
          <Box ml={2}>
            <Typography variant="h3">Attendees</Typography>
          </Box>
        </Header>
        <Box
          display="flex"
          flexDirection="row"
          pt={2}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <UserFilters />
          <Divider />
          <UsersList />
          <Divider />
          <UserProfile />
        </Box>
      </AttendeesBoard>
    </RootWrapper>
  );
});

export default Attendees;
