import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import PlayButton from "../../assets/images/play-button-arrowhead.svg";

const useStyles = makeStyles((theme) => ({
  lobbyLogoStyle: {
    position: "absolute",
    top: "29vh",
    left: "6.1vw",
    width: "11.5vw",
    height: "36vh",
    transform: "rotate(3.5deg)",
    cursor: "pointer",
    clipPath: "polygon(0% 0%, 97% 25%,99% 99%, 0% 100%)",
  },
  playButton: {
    position: "absolute",
    top: "31vh",
    left: "6.3vw",
    width: "11.5vw",
    height: "36vh",
    transform: "rotate(3deg)",
    cursor: "pointer",
  },
}));

interface LobbyLogoProps {
  lobbyLogo: string;
  handleOpen(): void;
}

const LobbyLogo = (props: LobbyLogoProps) => {
  const classes = useStyles();
  const { lobbyLogo, handleOpen } = props;

  return (
    <div>
      {lobbyLogo && (
        <img
          src={lobbyLogo}
          className={classes.lobbyLogoStyle}
          alt={"Event Logo"}
        />
      )}
      <img
        src={PlayButton}
        alt="play-button"
        onClick={() => handleOpen()}
        className={classes.playButton}
      />
    </div>
  );
};

export default LobbyLogo;
