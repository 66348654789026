import React, { Fragment } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventItem: {
      display: "flex",
      alignItems: "flex-start",
      boxSizing: "border-box",
    },
    eventTimeWrapper: {
      flex: "0 0 20%",
    },
    eventTime: {
      border: "1px solid #ffffff",
      margin: "5px",
      backgroundColor: "#ffffff",

      "& > h6": {
        textAlign: "center",
        padding: theme.spacing(2, 0),
        fontSize: "13px",
        margin: "5px",
      },
    },
    eventInfoWrapper: {
      flex: "0 0 80%",
      babckgroundColor: "#ffffff"
    },
    eventInfo: {
      backgroundColor: "#ffffff",
      margin: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0, 2),
      "& > h6": {
        padding: theme.spacing(2, 0),
        fontSize: "13px",
        margin: "5px",
      },
    },
    active: {
      color: "#F1312D",
      fontWeight: 700,
    },
    button: {
      backgroundColor: "#A19F9F",
      padding: theme.spacing(1, 3),
      color: "#ffffff",
      border: "none",
      cursor: "pointer",
      borderRadius: "4px",
      transition: "all .15s linear",
      "&:hover": {
        backgroundColor: "#F1312D",
      },
    },
  })
);

type ItemProps = {
  start: string;
  end: string;
  location: string;
  text: string;
  isRegistered: boolean;
};

export default function Item(props: ItemProps) {
  const { start, end, text, location, isRegistered } = props;
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.eventItem}>
        <div className={classes.eventTimeWrapper}>
          <div className={classes.eventTime}>
            <Typography variant="h6">
              <span className={classes.active}>
                {start} - {end}
              </span>{" "}
              UTC
            </Typography>
          </div>
        </div>
        <div className={classes.eventInfoWrapper}>
          <div className={classes.eventInfo}>
            <Typography variant="h6">
              <span className={classes.active}>{location}</span> {text}
            </Typography>
            {!isRegistered && (
              <button className={classes.button}>Register</button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
