import { BaseService, IResponse } from "./BaseService";
import { User, Login } from "../stores/models";

export default class UserService extends BaseService {
  public async fetchAll(): Promise<User[]> {
    try {
      const resp = await this.http.get("/api/v1/users");
      return this.decodeArray<User>(resp, User, "users");
    } catch (error) {
      throw error;
    }
  }

  public async byId(id: number): Promise<User> {
    try {
      const resp = await this.http.get(`/api/v1/users/${id}`);
      return this.decodeObject<User>(resp, User);
    } catch (error) {
      throw error;
    }
  }

  public async removeById(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/users/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async updateById(id: number, customer: User): Promise<Boolean> {
    const customerPayload = this.encodeObject(customer, User);
    try {
      const resp = await this.http.put(`/api/v1/users/${id}`, customerPayload);
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }

  public async create(customer: User): Promise<User> {
    const customerPayload = this.encodeObject(customer, User);
    try {
      const resp = await this.http.post(`/api/v1/users/`, customerPayload);
      return this.decodeObject<User>(resp, User);
    } catch (error) {
      throw error;
    }
  }

  public async login(data: Login): Promise<IResponse> {
    const loginPayload = this.encodeObject(data, Login);
    try {
      const resp = await this.http.post("/api/v1/login/user", loginPayload);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public setToken(token: string) {
    this.http.defaults.headers.common["token"] = token;
  }
}
