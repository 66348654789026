import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../stores/RootStore";

import VideoModal from "./VideoModal";
import LobbyVideo from "./LobbyVideo";
import LobbyButtons from "./LobbyButtons";
import ProgrammeModal from "./ProgrammeModal";

interface ISettings {
  store?: RootStore;
}

const Video: React.FC<ISettings> = ({ store }) => {
  const [openVideoModal, setOpenVideoModal] = React.useState(false);
  const [openProgrammeModal, setOpenProgrammeModal] = React.useState(false);

  useEffect(() => {
    const { settings } = store!.stores;
    (async () => await settings.fetchSettings())();
  }, [store]);

  const handleOpenVideoModal = () => {
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleCloseProgrammeModal = () => {
    setOpenProgrammeModal(false);
  };

  const handleOpenProgrammeModal = () => {
    setOpenProgrammeModal(true);
  };

  const { settingsList } = store!.stores.settings;

  const lobbyBanner =
    (settingsList?.find((el) => el.key === "EVENT_BANNER_ID")
      ?.value as string) || "";
  const lobbyLogo =
    (settingsList?.find((el) => el.key === "EVENT_LOGO_ID")?.value as string) ||
    "";
  const lobbyVideo =
    (settingsList?.find((el) => el.key === "EVENT_VIDEO_ID")
      ?.value as string) || "";

  return (
    <div>
      <LobbyVideo />
      <LobbyButtons
        handleOpenVideo={handleOpenVideoModal}
        handleOpenProgramme={handleOpenProgrammeModal}
        lobbyBanner={lobbyBanner}
        lobbyLogo={lobbyLogo}
      />
      <VideoModal
        openModal={openVideoModal}
        handleClose={handleCloseVideoModal}
        lobbyVideo={lobbyVideo}
      />
      <ProgrammeModal
        openModal={openProgrammeModal}
        handleClose={handleCloseProgrammeModal}
      />
    </div>
  );
};

export default inject("store")(observer(Video));
