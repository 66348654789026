import axios from "axios";
import API_CONFIG from "../services/config";
import { UserStore, UIStore, SessionStore } from "../stores";
import {
  UserService,
  SessionService,
  FileService,
  SettingsService,
} from "../services";
import FileStore from "./FileStore";
import VonageService from "../services/VonageService";
import SettingsStore from "./SettingsStore";

interface IServices {
  users: UserService;
  sessions: SessionService;
  files: FileService;
  vonage: VonageService;
  settings: SettingsService;
}

interface IStores {
  users: UserStore;
  ui: UIStore;
  sessions: SessionStore;
  files: FileStore;
  settings: SettingsStore;
}

class RootStore {
  services: IServices;
  stores: IStores;

  constructor() {
    // bootstraps http, services, stores
    const http = axios.create(API_CONFIG);
    http.interceptors.response.use(this._handleResponse, this._handleError);

    this.services = {
      users: new UserService(http),
      sessions: new SessionService(http),
      files: new FileService(http),
      vonage: new VonageService(http),
      settings: new SettingsService(http),
    };
    this.stores = {
      users: new UserStore(this),
      ui: new UIStore(this),
      sessions: new SessionStore(this),
      files: new FileStore(this),
      settings: new SettingsStore(this),
    };
  }

  private _handleResponse = (resp: any) => resp;

  private _handleError = (error: any) => {
    if (error?.response?.status !== 403) {
      return Promise.reject(error);
    }
    if (error?.response?.status === 403) {
      window.location.href = "/login";
      localStorage.removeItem("token");
    }
  };
}

const rootStore = new RootStore();
export { rootStore, RootStore };
