import { BaseService } from "./BaseService";

export default class FileService extends BaseService {

  public async downloadFile(fileId: number): Promise<File> {
    try {
      const response = await this.http.get(`/api/v1/files/download/${fileId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

};
