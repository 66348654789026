import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import videoUrl from "./entrance-lobby_s-rgb_07.mp4";

const useStyles = makeStyles((theme) => ({
  videoWrap: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
  },
  video: {
    height: "100%",
    width: "100%",
    objectFit: "fill",
  },
}));

const LobbyVideo = () => {
  const classes = useStyles();


  return (
    <div className={classes.videoWrap}>
      <video
        id="background-video"
        muted
        autoPlay
        loop
        preload="none"
        className={classes.video}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default LobbyVideo;
