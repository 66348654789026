import { BaseService } from "./BaseService";
import { Session } from "../stores/models";

export default class SessionService extends BaseService {

  public async fetchAll(): Promise<Session[]> {
    try {
      const resp = await this.http.get("/api/v1/sessions/");
      return this.decodeArray<Session>(resp, Session, 'sessions');
    } catch (error) {
      throw error;
    }
  };
};
