import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CameraOffIcon from "@material-ui/icons/VideocamOff";
import CameraIcon from "@material-ui/icons/Videocam";
import ShareScreenIcon from "@material-ui/icons/ScreenShare";
import React from "react";
import { MoreVert } from "@material-ui/icons";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      zIndex: 9999,
      margin: 0,
      padding: 0,
      backgroundColor: "#000",
    },
    left: {
      display: "flex",
      paddingLeft: 15,
      justifyContent: "flex-start",
    },
    right: {
      display: "flex",
      paddingRight: 15,
      justifyContent: "flex-end",
      marginRight: 0,
    },
    center: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface Props {
  onShareScreen?: VoidFunction;
  onMuteVideo: VoidFunction;
  onMuteAudio: VoidFunction;
  onEndCall: VoidFunction;
  onOpenDrawer?: VoidFunction;
  hasVideo: boolean;
  hasAudio: boolean;
  isPresenting: boolean;
  style?: any;
}

const RoomControls: React.FC<Props> = ({
  onShareScreen,
  onMuteVideo,
  onMuteAudio,
  hasAudio,
  onOpenDrawer,
  onEndCall,
  hasVideo,
  isPresenting,
  style,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={style}>
      <Grid container>
        <Grid item sm={4} className={classes.left}>
          <Button color="secondary" onClick={onEndCall}>
            Leave Session
          </Button>
        </Grid>
        <Grid item sm={4} className={classes.center}>
          <div style={{ margin: 10 }}>
            <IconButton
              onClick={onMuteAudio}
              color="primary"
              size="medium"
              style={{ backgroundColor: "#fff" }}
            >
              {hasAudio ? <MicIcon /> : <MicOffIcon />}
            </IconButton>
            &nbsp;&nbsp;
            <IconButton
              onClick={onMuteVideo}
              color="primary"
              size="medium"
              style={{ backgroundColor: "#fff" }}
            >
              {hasVideo ? <CameraIcon /> : <CameraOffIcon />}
            </IconButton>
            &nbsp;&nbsp;
            <IconButton
              onClick={onShareScreen}
              color="primary"
              size="medium"
              style={{ backgroundColor: !isPresenting ? "#fff" : "red" }}
            >
              <ShareScreenIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item sm={4} className={classes.right}>
          <IconButton onClick={onOpenDrawer} color="primary" size="medium">
            <MoreVert style={{ color: "#fff" }} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default RoomControls;
