import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const SelectControl = styled(FormControl)({
  "& div": {
    paddingLeft: "8px",
  },
});

const ExpandMoreIcon = styled(ExpandMoreRoundedIcon)({
  color: "#1F2127",
});

const SelectMenuProps = {
  autoFocus: false,
  variant: 'menu' as 'menu',
};

type SelectInputProps = {
  menuItems: string[];
  label: string;
  value: string;
  onChange: (arg: any) => void;
};

const SelectInput = (props: SelectInputProps) => {
  const { menuItems, label, value, onChange } = props;

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    onChange(event.target.value as string);
  };

  return (
    <SelectControl variant="filled">
      <InputLabel shrink={false}>{value === "" && label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        label={label}
        IconComponent={ExpandMoreIcon}
        disableUnderline
        MenuProps={SelectMenuProps}
      >
        {menuItems.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </SelectControl>
  );
};

export default SelectInput;
