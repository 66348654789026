import React from "react";

import { InputAdornment, TextField } from "@material-ui/core";

type TextFieldInputProps = {
  value: string;
  onChange: (arg: any) => void;
  inputAdornment: any;
  placeholder: string;
};

const TextFieldInput = (props: TextFieldInputProps) => {
  const { onChange, value, inputAdornment, placeholder } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as string);
  };

  return (
    <TextField
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">{inputAdornment}</InputAdornment>
        ),
      }}
    />
  );
};

export default TextFieldInput;
