import React from "react";
import { Box, styled } from "@material-ui/core";

const Root = styled(Box)({
  backgroundColor: "#F1F2F5",
  padding: "30px 150px 30px 150px",
  overflow: "auto",
  minHeight: "calc(100vh - 130px)",
});

type RootWrapperProps = {
  children: object;
};

const RootWrapper = (props: RootWrapperProps) => {
  const { children } = props;

  return <Root>{children}</Root>;
};

export default RootWrapper;
