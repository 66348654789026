import { RootStore } from "./RootStore";
import { observable, runInAction } from "mobx";
import { SettingsService } from "../services";
import { Setting } from "./models";

class SettingsStore {
  private root: RootStore;
  private api: SettingsService;

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.settings;
  }

  @observable public settingsList: Setting[] = [];

  public async fetchSettings() {
    try{
      const data = await this.api.fetchSettings();
      runInAction(() => {
        this.settingsList = data;
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
}

export default SettingsStore;
