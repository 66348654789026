import React from "react";
import {
  makeStyles,
  createStyles,
  Toolbar,
  Typography,
  AppBar,
} from "@material-ui/core";

const drawerWidth = 280;

const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  })
);

const Topbar: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar>
        <Typography variant="h6" noWrap></Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
