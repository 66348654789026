import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Setting")
class Setting {

  @JsonProperty("key", String)
  key: String = "";

  @JsonProperty("value", String)
  value: String = "";
  
}

export default Setting;
