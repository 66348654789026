import { RootStore } from "./RootStore";
import { observable, runInAction } from "mobx";

import SessionService from "../services/SessionService";
import { Session } from "./models";

class SessionStore {
  private root: RootStore;
  private api: SessionService;

  @observable public sessions: Session[] = [];

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.sessions;
  }

  public async getAllSessions() {
    try {
      const sessions = await this.api.fetchAll();
      runInAction(() => {
        this.sessions = sessions;
      });
    } catch (err) {
      console.log(err);
    }
  };

};

export default SessionStore;
