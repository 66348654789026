import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

import Navbar from "../Navbar/Navbar";
import {
  Attendees,
  Auditorium,
  Login,
  ResetLink,
  ResetPassword,
  Session,
  Video,
} from "../../screens";
import { useHistory } from "react-router-dom";

interface IUsers {
  store?: RootStore;
}

const Routes: React.FC<IUsers> = ({ store }) => {
  const { users } = store!.stores;
  const history = useHistory();

  React.useEffect(() => {
    if (!users.isLoggedin) {
      history.push("/login");
    } else {
      history.push(
        "/session/1_MX40Njk0NjY5NH5-MTYwMzA5NjUyNDA1OH5BczhoQk1KdTlSakNtWEFUUDhtYXV5YmJ-fg"
      );
    }
  }, []);

  return (
    <React.Fragment>
      {!!users.isLoggedin && <Navbar />}
      {!!users.isLoggedin ? (
        <Switch>
          <Route path="/session/:sessionID" exact component={Session} />
          <Route path="/attendees" exact component={Attendees} />
          <Route path="/auditorium" exact component={Auditorium} />
          <Route path="/" exact component={Video} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/forgot-password" exact component={ResetLink} />
          <Route path="/reset-password/:id" exact component={ResetPassword} />
          <Route path="/attendees" exact component={Attendees} />
          <Route path="/auditorium" exact component={Auditorium} />
        </Switch>
      )}
    </React.Fragment>
  );
};

export default inject("store")(observer(Routes));
