import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

let theme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      light: "#7986cb",
      main: "rgba(75, 72, 72, 1)",
      dark: "#303f9f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff4081",
      main: "rgba(252, 137, 3, 1)",
      dark: "#de7d0b",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    fontFamily: ["Open Sans"].join(","),
    fontWeightRegular: "normal",
  },
  overrides: {
    MuiTab: {
      root: {
        backgroundColor: "#FFFFFF",
        "&$selected": {
          backgroundColor: "#F5F5F5",
        },
      },
      textColorPrimary: {
        "&$selected": {
          color: "rgba(252, 137, 3, 1)",
        },
      },
    },
    MuiButton: {
      contained: {
        textTransform: "none",
        width: "100%",
        margin: "8px",
        color: "#ffffff",
        boxShadow: "none",
        height: "40px",
      },
      containedSecondary: {
        backgroundColor: '#463b2b',
        boxShadow: '0 0px 10px -2px #000',
        textTransform: "uppercase",
        borderRadius: 0,
        letterSpacing: '1.5px',
        fontSize: '12px',
        '&:hover': {
          backgroundColor: '#463b2b',
          opacity: '0.8'
        }
      }
    },
    MuiTextField: {
      root: {
        "& div > input": {
          fontSize: "12px",
          color: "#1F2127",
          fontWeight: "bold",
          letterSpacing: "0.3px",
          opacity: 6,
        },
      },
    },
    MuiFormControl: {
      root: {
        backgroundColor: "#F1F2F5",
        borderRadius: "4px",
        width: "200px",
        "& label": {
          transform: "translate(15px, 14px) scale(1)",
          fontSize: "12px",
          color: "#1F2127",
          fontWeight: "bold",
          letterSpacing: "0.3px",
        },
      },
    },
    MuiInputBase: {
      root: {
        height: "40px",
        width: "270px",
        backgroundColor: "#F1F2F5 !important",
        borderRadius: "4px",
        paddingLeft: "15px",
        paddingRight: "8px",
      },
    },
    MuiSelect: {
      root: {
        paddingTop: "12px",
      },
      select: {
        "&:focus": {
          backgroundColor: "unset",
        },
      },
    },
    MuiMenu: {
      paper: {
        marginTop: "50px",
      },
    },
  },
});

theme.typography.h3 = {
  fontSize: theme.typography.pxToRem(20),
  fontWeight: "bold",
  lineHeight: 2,
  fontFamily: "Open Sans",
  color: "#1f2127",
};

theme.typography.h4 = {
  fontSize: theme.typography.pxToRem(18),
  fontWeight: "bold",
  lineHeight: 2,
  fontFamily: "Open Sans",
  color: "#1f2127",
};

theme.typography.h5 = {
  fontSize: theme.typography.pxToRem(12),
  fontWeight: "bold",
  letterSpacing: "0.24px",
  fontFamily: "Open Sans",
  color: "#1f2127",
};

theme.typography.h6 = {
  fontSize: theme.typography.pxToRem(12),
  fontWeight: "bold",
  letterSpacing: "0.24px",
  fontFamily: "Open Sans",
  color: "#908F90",
};

theme.typography.body1 = {
  fontSize: theme.typography.pxToRem(14),
  fontWeight: "bold",
  lineHeight: 1.3,
  fontFamily: "Open Sans",
};

theme.typography.body2 = {
  fontSize: theme.typography.pxToRem(14),
  fontFamily: "Open Sans",
};

theme.typography.button = {
  fontSize: theme.typography.pxToRem(14),
  fontWeight: "bold",
  letterSpacing: "0.35px",
};

export default theme;
