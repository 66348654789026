import { BaseService } from "./BaseService";
import { Setting } from "../stores/models";

export default class SettingsService extends BaseService {
  
  public async fetchSettings(): Promise<Setting[]> {
    try {
      const resp = await this.http.get("/api/v1/settings/");
      return this.decodeArrayVcp<Setting>(resp, Setting);
    } catch (error) {
      throw error;
    }
  }
  
}
