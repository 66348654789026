import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import LobbyBanner from "./LobbyBanner";
import LobbyLogo from "./LobbyLogo";

const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: "12vw",
    backgroundColor: "#453b2a",
    "&:hover": {
      backgroundColor: "#453b2a",
    },
    color: "white",
    position: "absolute",
    padding: "0.5rem 1rem",
    letterSpacing: "2px",
    borderRadius: 0,
  },
  networkingBtn: {
    top: "38vh",
    left: "22vw",
  },
  breakoutBtn: {
    top: "38vh",
    right: "22vw",
  },
  auditoriumBtn: {
    bottom: "38vh",
    left: "22vw",
  },
  expoBtn: {
    bottom: "38vh",
    right: "22vw",
  },
  helpDeskBtn: {
    bottom: "18vh",
    right: "42.9vw",
    minWidth: "13vw",
  },
  lobbyLogoStyle: {
    position: "absolute",
    top: "34vh",
    left: "5.6vw",
    width: "200px",
    height: "300px",
  },
}));

interface LobbyButtonsProps {
  handleOpenVideo(): void;
  handleOpenProgramme(): void;
  lobbyBanner: string;
  lobbyLogo: string;
}

const LobbyButtons = (props: LobbyButtonsProps) => {
  const { lobbyBanner, lobbyLogo, handleOpenProgramme, handleOpenVideo } = props;
  const classes = useStyles();

  return (
    <div>
      {/* <Button
        className={clsx(classes.btn, classes.networkingBtn)}
      >
        NETWORKING LOUNGE
      </Button>
      <Button className={clsx(classes.btn, classes.breakoutBtn)}>
        BREAKOUT ROOMS
      </Button>
      <Button className={clsx(classes.btn, classes.auditoriumBtn)}>
        AUDITORIUM
      </Button>
      <Button className={clsx(classes.btn, classes.expoBtn)}>EXPO HALL</Button>
      <Button className={clsx(classes.btn, classes.helpDeskBtn)}>
        HELP DESK
      </Button> */}
      <LobbyBanner lobbyBanner={lobbyBanner} handleOpen={handleOpenProgramme}/>
      <LobbyLogo lobbyLogo={lobbyLogo} handleOpen={handleOpenVideo}/>
    </div>
  );
};

export default LobbyButtons;
