import { RootStore } from "./RootStore";
import { FileService } from "../services";
import { observable, runInAction } from "mobx";
import { File } from "./models";

class FileStore {
  private root: RootStore;
  private api: FileService;

  @observable public file: File = new File();

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.files;
  }

  public async downloadFile(fileId: number) {
    try {
      let response = await this.api.downloadFile(fileId);
      runInAction(() => {
        this.file.file = response.toString();
      });
    } catch (err) {
      console.error(err);
    }
  };

}

export default FileStore;
